@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Apis For Office";
	src:
		url("./fonts/apisforoffice-regular-webfont.woff2") format("woff2"),
		url("./fonts/apisforoffice-regular-webfont.woff") format("woff"),
		url("./fonts/apisforoffice-regular-webfont.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Apis For Office";
	src:
		url("./fonts/apis-bold-webfont.woff2") format("woff2"),
		url("./fonts/apis-bold-webfont.woff") format("woff"),
		url("./fonts/apis-bold-webfont.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

body {
	color: #000;
	font-family: "Apis For Office";
	font-size: 16px;
}

p {
	font-size: 16px;
	line-height: 24px;
}

a,
a:hover {
	color: #005ad2;
	text-decoration: underline;
}
