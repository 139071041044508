.supabase__auth {
	--fonts-labelFontFamily: "Apis For Office";
	--fontSizes-baseLabelSize: 11px;
	--colors-inputLabelText: red;
	--space-labelBottomMargin: 20px;
	--borderWidths-inputBorderWidth: 0;
	--colors-brandAccent: #005ad2;
}

.supabase__auth .supabase-auth-ui_ui-button {

	@apply bg-mariner text-white hover:bg-science-blue focus:bg-science-blue rounded-md py-1.5 px-8 font-bold text-base inline-flex w-auto self-start;
}

.supabase__auth .supabase__label {
	display: none;
}

.supabase__auth .supabase__input {

	@apply border text-base border-timberwolf p-3 placeholder:text-timberwolf text-cerulean;
}

